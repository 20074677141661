import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import PayPal from "./Paypal";

import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import { useEffect, useRef } from "react";
// This value is from the props in the UI
const buttonStyle = { layout: "vertical" };

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight: 220,
  overflowY: "hidden",
  bgcolor: "#f0f0f0",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

export default function PaymentModal({
  open,
  handleClose,
  handlePaymentSubmit,
}) {
  // Custom component to wrap the PayPalButtons and show loading spinner
  // const ButtonWrapper = ({ showSpinner }) => {
  //   const [{ isPending }] = usePayPalScriptReducer();

  //   return (
  //     <>
  //       {showSpinner && isPending && <div className="spinner" />}
  //       <PayPalButtons
  //         style={buttonStyle}
  //         disabled={false}
  //         forceReRender={[style]}
  //         fundingSource={undefined}
  //         createOrder={createOrder}
  //         onApprove={onApprove}
  //       />
  //     </>
  //   );
  // };

  return (
    <div>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              maxWidth: "750px",
              maxHeight: "300px",
              overflowY: "scroll",
            }}
          >
            {/* <PayPalScriptProvider
                options={{
                  clientId: "test",
                  components: "buttons",
                  currency: "USD",
                }}
              >
                <ButtonWrapper showSpinner={false} />
              </PayPalScriptProvider> */}
            {open && <PayPal handlePaymentSubmit={handlePaymentSubmit} />}
          </div>

          {/* <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button onClick={handleClose} className="main-button">
              Close
            </Button>
          </div> */}
        </Box>
      </Modal>
    </div>
  );
}
