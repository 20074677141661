import React, { useEffect, useRef } from "react";

export default function Paypal({ handlePaymentSubmit }) {
  const paypal = useRef();

  useEffect(() => {
    const cart = localStorage.getItem("Cart");
    if (cart) {
      const currentCart = JSON.parse(cart);

      // Check if script is already loaded before adding a new one
      if (!window.paypal) {
        const script = document.createElement("script");
        script.src =
          "http://www.paypal.com/sdk/js?client-id=AZBiczS2P_TmKUcm7-gfxr4iJ-Gy4RJePLH7VyJYgzIijHMaTHu3-LvzoNMrnR1M67J4NwsaZSoXM_xU&currency=USD";
        script.addEventListener("load", () =>
          initializePaypalButton(currentCart)
        );
        document.body.appendChild(script);
      } else {
        initializePaypalButton(currentCart);
      }
    }

    // Empty dependency array to prevent infinite loop
  }, []); // Empty dependency array

  function initializePaypalButton(cart) {
    try {
      let total = 0;
      cart.map((item) => {
        total = total + item.productPrice * item.Quantity;
      });
      const paypalButtons = window.paypal.Buttons({
        createOrder: (data, actions, err) => {
          return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
              // {
              //  description: "4D Wrangler",
              //  amount: {
              //   currency_code: "USD",
              //   value: 120.0,
              //  },
              // },
              {
                items: cart.map((item) => {
                  return {
                    name: item.productName,
                    description: "item.productDescription",
                    quantity: item.Quantity.toString(),
                    unit_amount: {
                      currency_code: "USD",
                      value: item.productPrice.toFixed(2).toString(),
                    },
                  };
                }),
                amount: {
                  currency_code: "USD",
                  value: total.toFixed(2).toString(),
                  breakdown: {
                    item_total: {
                      currency_code: "USD",
                      value: total.toFixed(2).toString(),
                    },
                  },
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          handlePaymentSubmit(order);
        },
        onError: (err) => {
          console.error("PayPal Buttons onError:", err);
          handlePaymentSubmit(err);
        },
      });

      paypalButtons.render(paypal.current);

      return () => {
        paypalButtons.close();
      };
    } catch (error) {
      console.error("Error initializing PayPal Buttons:", error);
    }
  }

  return (
    <div>
      <div ref={paypal}></div>
    </div>
  );
}
