import React, { useState, useEffect } from "react";
import { Button, Divider, Grid } from "@mui/material";
import PopupModal from "../Components/popupModal";
import PaymentModal from "../Components/paymentPopup";
import { Link, useNavigate } from "react-router-dom";
import homeHero from "../assets/svg/homePageHero.svg";
import { Label, Input } from "@mui/icons-material";

import { Save_checkout } from "../Utilities/Checkout";

const Checkout = () => {
  const navigation = useNavigate();

  const [cart, setCart] = useState([]);
  const [cartTotal, setCartTotal] = useState(0);

  const [open, setOpen] = useState(false);
  const [modalText, setModalText] = useState("");

  const [openPayment, setOpenPayment] = useState(false);

  //Form States
  const [FullName, setFullName] = useState("");
  const [Phone, setPhone] = useState("");
  const [Email, setEmail] = useState("");
  const [Country, setCountry] = useState("");
  const [Address, setAddress] = useState("");
  const [Upfront, setUpfront] = useState(0);
  const [Message, setMessage] = useState("");
  const [Image, setImage] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const handleClosePayment = () => {
    setOpenPayment(false);
  };

  useEffect(() => {
    const cart = localStorage.getItem("Cart");
    if (cart) {
      const currentCart = JSON.parse(cart);
      setCart(currentCart);
      let total = 0;
      currentCart.map((item) => {
        total = total + item.productPrice * item.Quantity;
      });
      setCartTotal(total);
    }
  }, []);

  let Web_User = localStorage.getItem("Web_User");
  if (Web_User) {
    const user = JSON.parse(Web_User);
    Web_User = user.id;
  } else {
    Web_User = "";
  }

  useEffect(() => {
    // window.scrollTo(0, 700);
  }, []);

  const handlePaymentSubmit = async (event) => {
    console.log(event);
    if (event.status && event.status === "COMPLETED") {
      handleFinalSave();
    } else {
      setOpenPayment(false);
    }
  };

  const handleSubmit = async () => {
    console.log(cart);
    if (cart.length == 0) {
      setModalText("Please add a product in your cart.");
      setOpen(true);
    } else if (FullName == "") {
      setModalText("Please enter your full name.");
      setOpen(true);
    } else if (Phone == "") {
      setModalText("Please enter your phone.");
      setOpen(true);
    } else if (Email == "") {
      setModalText("Please enter your email.");
      setOpen(true);
    } else if (Country == "") {
      setModalText("Please enter your country.");
      setOpen(true);
    } else if (Address == "") {
      setModalText("Please enter your address.");
      setOpen(true);
    } else {
      setOpenPayment(true);
    }
  };

  const handleFinalSave = async () => {
    const body = {
      fullName: FullName,
      contact: Phone,
      emailAddress: Email,
      country: Country,
      address: Address,
      upfront: Web_User !== "" ? Upfront : 0,
      message: Message,
      customerId: Web_User,
      products: cart,
      totalPrice: cartTotal,
      jeepImage: Image,
    };
    try {
      const resp = await Save_checkout(body);
      if (resp.Status_Code === 200 && resp.IsSuccess === true) {
        setModalText(
          "Thank you for shopping with us. You will get a confirmation email soon."
        );
        setOpen(true);
        localStorage.removeItem("Cart");
        setFullName("");
        setPhone("");
        setEmail("");
        setCountry("");
        setAddress("");
        setUpfront(0);
        setMessage("");
        setImage("");
        setCart([]);
        setCartTotal(0);
        setOpenPayment(false);
        navigation("/");
      } else {
        setModalText(resp.message);
        console.log(resp.message);
        setOpen(true);
      }
    } catch (error) {
      console.log(error);
      setModalText(error);
      setOpen(true);
    }
  };

  //-------Upload File-------------
  const convertBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result.replace("data:", "").replace(/^.+,/, ""));
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const handleUploadtImage = (files, previewUrl) => {
    if (files.length > 0 && files.length < 5) {
      const filesArray = [];
      files.map(async (file) => {
        const fileSize = files.size;
        if (fileSize > 2097152) {
          alert(`Maximum file size is 2 Mb.`);
        } else if (file.type === "image/png" || file.type === "image/jpeg") {
          await convertBase64(file, (result) => {
            const newItem = {
              Name: file.name,
              fileType: file.type,
              File: result,
              previewUrl: previewUrl,
            };
            filesArray.push(newItem);
          });
        } else {
          alert(`Only Images are allowed.`);
        }
      });
      setImage(filesArray);
    } else {
      alert(`Cannot upload more than 4 images.`);
    }
  };

  const handleImageEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    setImage(null);
    const file = e.target.files[0];
    const reader = new FileReader();
    let previewUrl;
    reader.onload = () => {
      previewUrl = reader.result;
      handleUploadtImage(chosenFiles, previewUrl);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <div className="shoppage-hero-container">
        <Grid container spacing={2}>
          <Grid
            item
            md={6}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <div
              style={{
                width: "70%",
                color: "white",
              }}
            >
              <p className="contact-hero-title">CHECKOUT</p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <p
                  style={{
                    fontSize: "22px",
                    fontWeight: "500",
                  }}
                >
                  HOME - CHECKOUT
                </p>
                <div className="divider-hero"></div>
              </div>
            </div>
          </Grid>
          <Grid item md={6} className="hero-section-image">
            <img src={homeHero} alt="" style={{ width: "100%" }} />
          </Grid>
        </Grid>
      </div>

      <div
        style={{
          width: "50%",
          height: 50,
          backgroundColor: "#2b2b2b",
          marginTop: -50,
          borderTopRightRadius: 50,
        }}
      ></div>

      <div className="checkout-form-main-container">
        <div className="checkout-form-container">
          <Grid
            container
            spacing={2}
            style={{
              margin: "20px 0px",
            }}
          >
            <Grid md={6} sm={12} xs={12} item>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingRight: 20,
                }}
              >
                <p className="contact-form-heading">CHECKOUT</p>
                <div className="form-divider1"></div>
              </div>
              <Grid container spacing={2}>
                <Grid md={6} sm={12} item style={{ width: "90%" }}>
                  <input
                    type="text"
                    placeholder="Full Name"
                    value={FullName}
                    onChange={(e) => setFullName(e.target.value)}
                    style={{
                      width: "100%",
                      backgroundColor: "#393939",
                      color: "#fff",
                      fontSize: 14,
                      padding: "3px 10px",
                      border: "none",
                      height: 40,
                    }}
                  />
                </Grid>
                <Grid md={6} sm={12} item style={{ width: "90%" }}>
                  <input
                    type="text"
                    placeholder="Phone"
                    value={Phone}
                    onChange={(e) => setPhone(e.target.value)}
                    style={{
                      width: "100%",
                      backgroundColor: "#393939",
                      color: "#fff",
                      fontSize: 14,
                      padding: "3px 10px",
                      border: "none",
                      height: 40,
                    }}
                  />
                </Grid>
                <Grid md={12} sm={12} item style={{ width: "90%" }}>
                  <input
                    type="email"
                    placeholder="Email"
                    value={Email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{
                      width: "100%",
                      backgroundColor: "#393939",
                      color: "#fff",
                      fontSize: 14,
                      padding: "3px 10px",
                      border: "none",
                      height: 40,
                    }}
                  />
                </Grid>
                <Grid md={12} sm={12} item style={{ width: "90%" }}>
                  <input
                    type="text"
                    placeholder="Country"
                    value={Country}
                    onChange={(e) => setCountry(e.target.value)}
                    style={{
                      width: "100%",
                      backgroundColor: "#393939",
                      color: "#fff",
                      fontSize: 14,
                      padding: "3px 10px",
                      border: "none",
                      height: 40,
                    }}
                  />
                </Grid>
                <Grid md={12} sm={12} item style={{ width: "90%" }}>
                  <input
                    type="text"
                    placeholder="Address"
                    value={Address}
                    onChange={(e) => setAddress(e.target.value)}
                    style={{
                      width: "100%",
                      backgroundColor: "#393939",
                      color: "#fff",
                      fontSize: 14,
                      padding: "3px 10px",
                      border: "none",
                      height: 40,
                    }}
                  />
                </Grid>
                {Web_User !== "" && (
                  <Grid md={12} sm={12} item style={{ width: "90%" }}>
                    <input
                      type="number"
                      placeholder="Upfront"
                      value={Upfront}
                      onChange={(e) => setUpfront(e.target.value)}
                      style={{
                        width: "100%",
                        backgroundColor: "#393939",
                        color: "#fff",
                        fontSize: 14,
                        padding: "3px 10px",
                        border: "none",
                        height: 40,
                      }}
                    />
                  </Grid>
                )}

                <Grid md={12} sm={12} item style={{ width: "90%" }}>
                  <textarea
                    type="text"
                    placeholder="Message"
                    value={Message}
                    onChange={(e) => setMessage(e.target.value)}
                    className="contact-form-textarea"
                  />
                </Grid>
                <Grid md={12} sm={12} item style={{ width: "90%" }}>
                  <p style={{ color: "white" }}>
                    {/* Please upload a few images of your Jeep above to ensure the
                    design matches it perfectly */}
                    Please upload 5 photos of your Jeep.
                  </p>
                  <input
                    type="file"
                    multiple={true}
                    onChange={handleImageEvent}
                    style={{
                      width: "100%",
                      backgroundColor: "#393939",
                      color: "#fff",
                      fontSize: 14,
                      padding: "8px 10px",
                      border: "none",
                      height: 40,
                      marginTop: -5,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid md={6} sm={12} xs={12} item className="form-image">
              <div
                style={{
                  width: "90%",
                  background: "#505050",
                  padding: "10px 0px",
                }}
              >
                <p className="checkout-form-heading">YOUR ORDER</p>
                <Divider style={{ color: "#ffffff", margin: "5px 0px" }} />
                <Grid
                  container
                  spacing={3}
                  style={{
                    margin: "5px 0px",
                    height: "130px",
                    overflowY: cart.length >= 2 ? "scroll" : "hidden",
                  }}
                >
                  {cart.map((item) => {
                    return (
                      <>
                        <Grid md={6} sm={6} xs={6} item>
                          <p style={{ color: "white", fontWeight: "800" }}>
                            <span>{item.productName}</span>
                            <br />
                            <span style={{ fontSize: 10 }}>
                              {item.vehicleParts.join(", ")}
                            </span>
                          </p>
                        </Grid>
                        <Grid md={6} sm={6} xs={6} item>
                          <p style={{ color: "#ff3132" }}>
                            {"$" +
                              item.productPrice.toLocaleString() +
                              " x" +
                              item.Quantity}
                          </p>
                        </Grid>
                      </>
                    );
                  })}
                </Grid>
                <Divider style={{ color: "#ffffff", margin: "5px 0px" }} />
                <Grid container spacing={2} style={{ margin: "5px 0px" }}>
                  <Grid md={6} sm={6} xs={6} item>
                    <p className="checkout-form-main">SUBTOTAL</p>
                  </Grid>
                  <Grid md={6} sm={6} xs={6} item>
                    <p className="checkout-form-main-price">
                      {"$" + cartTotal.toLocaleString()}
                    </p>
                  </Grid>
                  <Grid md={6} sm={6} xs={6} item>
                    <p className="checkout-form-main">TOTAL PRICE</p>
                  </Grid>
                  <Grid md={6} sm={6} xs={6} item>
                    <p className="checkout-form-main-price">
                      {"$" + cartTotal.toLocaleString()}
                    </p>
                  </Grid>
                  <Grid md={6} sm={6} xs={6} item>
                    <p className="checkout-form-main">UPFRONT</p>
                  </Grid>
                  <Grid md={6} sm={6} xs={6} item>
                    <p className="checkout-form-main-price">
                      {"$" + Upfront.toLocaleString()}
                    </p>
                  </Grid>
                </Grid>
              </div>
              <Grid
                md={10}
                sm={10}
                item
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-end",
                  marginTop: 15,
                }}
              >
                <Button onClick={handleSubmit} className="main-button">
                  Submit Form
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>

      <div className="mid-banner-2">
        <div>
          <p
            style={{
              fontWeight: "500",
              fontSize: "22px",
            }}
          >
            DRIVE BEYOND BOUNDARIES
          </p>
        </div>
        <p
          style={{
            fontWeight: "800",
            fontSize: "44px",
            lineHeight: "52px",
            textAlign: "center",
          }}
        >
          CUSTOMIZE DESIGN
        </p>
        <Link
          onClick={(e) => {
            localStorage.removeItem("modelParts");
          }}
          to="/build-your-own?q=0001"
          style={{ textDecoration: "none", color: "black" }}
        >
          <Button className="main-button">Customize Your Jeep</Button>
        </Link>
      </div>

      <PopupModal open={open} modalText={modalText} handleClose={handleClose} />
      <PaymentModal
        open={openPayment}
        handleClose={handleClosePayment}
        handlePaymentSubmit={handlePaymentSubmit}
      />
    </div>
  );
};

export default Checkout;
